import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { required } from '../../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../../components';
import config from '../../../config';
import getCountryCodes from '../../../translations/countryCodes';
import debounce from 'lodash/debounce';
import css from './ShippingDetailsForm.module.css';
import CarrierCard from '../CarrierCard';
import { isArray } from '../../../util/dataExtrators';

import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import '../../../forms/ContactDetailsForm/phoneNumberInputGlobal.css';

class ShippingDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { phoneValue: this.props.savedPhoneNumber };
    this.setPhoneValue = this.setPhoneValue.bind(this);
    this.submittedValues = {};
  }

  setPhoneValue(phone, form) {
    form.change('phoneNumber', phone);
    const formattedNumber = formatPhoneNumberIntl(phone);
    if (isValidPhoneNumber(formattedNumber)) {
      this.setState({ invalidPhoneNumber: false });
    } else {
      this.setState({ invalidPhoneNumber: true });
    }
    this.setState({ phoneValue: phone });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            formId,
            className,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            values,
            carriers,
            onSelectCarrier,
            carrierPreferences,
            activeIndex,
            form,
            profile,
          } = fieldRenderProps;

          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = values?.phone || protectedData.phoneNumber;

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });

          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          const streetAddressLabelMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.streetAddress',
          });
          const streetAddressPlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.streetAddressPlaceholder',
          });
          const streetAddressRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.streetAddressRequired',
          });

          const buildingLabelMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.building',
          });
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.buildingPlaceholder',
          });

          const postalLabelMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.postal',
          });
          const postalPlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.postalPlaceholder',
          });
          const postalRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.postalRequired',
          });

          const cityLabelMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.city',
          });
          const cityPlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.cityPlaceholder',
          });
          const cityRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.cityRequired',
          });

          const stateLabelMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.state',
          });
          const statePlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.statePlaceholder',
          });
          const stateRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.stateRequired',
          });

          const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
          const countryPlaceholder = intl.formatMessage({
            id: 'StripePaymentAddress.countryPlaceholder',
          });
          const countryRequired = required(
            intl.formatMessage({
              id: 'StripePaymentAddress.countryRequired',
            })
          );
          const countryCodes = getCountryCodes(config.locale);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = invalid || pristine || submitInProgress;
          console.log(this.state.invalidPhoneNumber);
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.shippingAddressTitle" />
                </h3>
                <div className={css.inputContainer}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.address` : 'address'}
                    type="text"
                    name="line1"
                    label={streetAddressLabelMessage}
                    placeholder={streetAddressPlaceholderMessage}
                    validate={required(streetAddressRequiredMessage)}
                  />
                  <FieldTextInput
                    className={css.secondInput}
                    id={formId ? `${formId}.apt` : 'apt'}
                    type="text"
                    name="line2"
                    label={buildingLabelMessage}
                    placeholder={buildingPlaceholderMessage}
                  />
                </div>
                <div className={css.inputContainer}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.city` : 'city'}
                    type="text"
                    name="city"
                    label={cityLabelMessage}
                    placeholder={cityPlaceholderMessage}
                    validate={required(cityRequiredMessage)}
                  />
                  <FieldTextInput
                    className={css.secondInput}
                    id={formId ? `${formId}.zip` : 'zip'}
                    type="text"
                    name="postal_code"
                    label={postalLabelMessage}
                    placeholder={postalPlaceholderMessage}
                    validate={required(postalRequiredMessage)}
                  />
                </div>
                <div className={css.inputContainer}>
                  <FieldTextInput
                    className={css.firstInput}
                    id={formId ? `${formId}.state` : 'state'}
                    type="text"
                    name="state"
                    label={stateLabelMessage}
                    placeholder={statePlaceholderMessage}
                    validate={required(stateRequiredMessage)}
                  />
                  <div className={css.countryWrapper}>
                    <FieldSelect
                      id={formId ? `${formId}.country` : 'country'}
                      name="country"
                      className={css.field}
                      label={countryLabel}
                      validate={countryRequired}
                    >
                      <option disabled value="">
                        {countryPlaceholder}
                      </option>
                      {countryCodes.map(country => {
                        return (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        );
                      })}
                    </FieldSelect>
                  </div>
                </div>
                <div className={css.phoneNumber}>
                  <div style={{ width: '100%' }}>
                    <label>{phoneLabel} </label>
                    <PhoneInput
                      defaultCountry="FR"
                      placeholder={phonePlaceholder}
                      id={formId ? `${formId}.phone` : 'phone'}
                      value={values?.phone || this.state.phoneValue}
                      onChange={debounce(phone => this.setPhoneValue(phone, form), 1000)} // Debounce for 2 seconds (2000 milliseconds)
                      className={css.phoneNumber}
                    />
                    {this.state.invalidPhoneNumber && (
                      <span className={css.error}>
                        <FormattedMessage id="ProfileSettingsForm.enterValidPhone" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={!this.state.phoneValue || this.state.invalidPhoneNumber || invalid}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
              <h3 className={css.sectionTitle}>
                <FormattedMessage id="ProfileSettingsForm.shippingCarriers" />
              </h3>
              <div className={css.carriers}>
                {isArray(carriers)
                  ? carriers.map((item, index) => (
                      <CarrierCard
                        key={index}
                        carrier={item}
                        onSelectCarrier={onSelectCarrier}
                        carrierPreferences={carrierPreferences}
                        updateInProgress={updateInProgress}
                        isSelected={activeIndex === index}
                        index={index}
                      />
                    ))
                  : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ShippingDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ShippingDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ShippingDetailsForm = compose(injectIntl)(ShippingDetailsFormComponent);

ShippingDetailsForm.displayName = 'ShippingDetailsForm';

export default ShippingDetailsForm;
