import React from 'react';
import SelectedIcon from '../../forms/ShippingForm/SelectedIcon';
import { isArray } from '../../util/dataExtrators';
import classNames from 'classnames';
import css from './ShippingDetailsPage.module.css';
import { IconSpinner } from '../../components';
import { defaultCarrierImages } from '../../custom-config';

function CarrierCard(props) {
  const {
    carrier,
    onSelectCarrier,
    carrierPreferences,
    updateInProgress,
    index,
    isSelected,
  } = props;

  if (!carrier) {
    return <></>;
  }

  const name = carrier?.carrier_name;
  const carrierImages = carrier?.carrier_images;
  const pixel200Image = carrierImages && carrierImages['200'];
  const assestImage = defaultCarrierImages?.find(item => item.label === name)?.image;

  const selected =
    isArray(carrierPreferences) && carrierPreferences.find(item => item.carrier_name === name);

  return (
    <div
      onClick={() => onSelectCarrier(carrier, selected?.account_id, index)}
      className={css.carriersContainer}
    >
      <div
        className={classNames(css.cardCarrier, {
          [css.selectedCard]: selected,
        })}
      >
        <div className={css.cardBox}>
          <div className={css.providerImage}>
            <img src={assestImage || pixel200Image} alt={name} />
          </div>
          <div className={css.cardCarrierData}>
            <div className={css.title}>
              <div className={css.providerName}>{name}</div>
            </div>
            {/* <div className={css.estDeliveryText}>
              {terms ? terms : `Delivery in ${estimatedDays} days`}
            </div> */}
          </div>
        </div>
        <span style={{ lineHeight: '100%' }} role="button">
          {updateInProgress && isSelected ? <IconSpinner /> : <SelectedIcon selected={selected} />}
        </span>
      </div>
    </div>
  );
}

export default CarrierCard;
