import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '..';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getCarriers } from './ShippingDetailsPage.duck';
import css from './ShippingDetailsPage.module.css';
import { getCarrierPreferences, isArray } from '../../util/dataExtrators';
import CarrierCard from './CarrierCard';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import ShippingDetailsForm from './ShippingDetailsForm/ShippingDetailsForm';
import { removeDuplicates } from '../../util/genericHelpers';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

export const ShippingDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onUpdateProfile,
    updateInProgress,
    updateProfileError,
  } = props;

  const dispatch = useDispatch();
  const [carriers, setCarriers] = useState([]);
  const [inProgress, setInProgess] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const allCarriers = async () => {
    const { allCarriers = [] } = await dispatch(getCarriers());
    setCarriers(allCarriers);
    setInProgess(false);
  };

  useEffect(() => {
    setInProgess(true);
    allCarriers();
  }, []);

  const user = ensureCurrentUser(currentUser);
  const title = intl.formatMessage({ id: 'ShippingDetailsPage.title' });
  const carrierPreferences = getCarrierPreferences(user);

  const handleSubmit = values => {
    const { line1, line2, city, postal_code, state, country, phone, phoneNumber } = values;

    const shippingAddress = {
      name: `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`,
      line1,
      line2,
      city,
      postal_code,
      state,
      country,
      phone: phoneNumber || phone,
    };

    const profile = {
      publicData: {
        shippingAddress,
      },
      protectedData: {
        phoneNumber: phoneNumber || phone,
      },
    };
    onUpdateProfile(profile, false);
  };

  const onSelectCarrier = (c, isSelected, index) => {
    setActiveIndex(index);
    if (isSelected) {
      const filteredCarriers =
        isArray(carrierPreferences) &&
        carrierPreferences.filter(item => item.account_id !== isSelected);
      onUpdateProfile(
        {
          publicData: {
            carrierPreferences: filteredCarriers,
          },
        },
        true
      );
    } else {
      carrierPreferences.push(c);
      const preferences =
        Array.isArray(carrierPreferences) &&
        carrierPreferences?.filter(
          (v, i, a) => a?.findIndex(v2 => v2?.carrier === v?.carrier) === i
        );
      onUpdateProfile(
        {
          publicData: {
            carrierPreferences: preferences,
          },
        },
        true
      );
    }
  };

  const savedShippoAddress = user?.attributes?.profile?.protectedData?.shippoAddress;
  const phoneNumber =
    user?.attributes?.profile?.protectedData?.phoneNumber || savedShippoAddress?.phone;
  const formattedNumber = formatPhoneNumberIntl(savedShippoAddress?.phone);

  const savedPhoneNumber = phoneNumber || formattedNumber;

  const initialValues = savedShippoAddress
    ? {
        name: `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`,
        line1: savedShippoAddress?.street1,
        line2: savedShippoAddress?.street2,
        city: savedShippoAddress?.city,
        postal_code: savedShippoAddress?.zip,
        state: savedShippoAddress?.state,
        country: savedShippoAddress?.country,
        phone: savedPhoneNumber,
      }
    : {};

  const shippingDetailsForm = user.id ? (
    <ShippingDetailsForm
      className={css.form}
      currentUser={currentUser}
      initialValues={initialValues}
      savedPhoneNumber={savedPhoneNumber}
      profile={user?.attributes?.profile}
      updateInProgress={updateInProgress}
      updateProfileError={updateProfileError}
      onSubmit={handleSubmit}
      carriers={isArray(carriers) ? removeDuplicates(carriers, ['carrier_name']) : []}
      onSelectCarrier={onSelectCarrier}
      carrierPreferences={carrierPreferences}
      activeIndex={activeIndex}
    />
  ) : null;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ShippingDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ShippingDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="ShippingDetailsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ShippingDetailsPage.heading" />
            </h1>
            <div className={css.shippingDetailsContainer}>
              {inProgress ? (
                <div className={css.loader}>
                  <IconSpinner />
                  <FormattedMessage id="ShippingDetailsPage.fetching" />
                </div>
              ) : null}
              <div className={css.carriers}>{shippingDetailsForm}</div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ShippingDetailsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func } = PropTypes;

ShippingDetailsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { updateInProgress, updateProfileError } = state.ProfileSettingsPage;
  return {
    currentUser,
    updateInProgress,
    updateProfileError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: (data, shouldUpdateAddress) =>
    dispatch(updateProfile(data, shouldUpdateAddress)),
});

const ShippingDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ShippingDetailsPageComponent);

export default ShippingDetailsPage;
